import React, { useEffect } from "react";
import Web3 from "web3";
import abi from "./abi/abis.json";
import { useWallet } from "use-wallet";

import {
  accountUpdate,
  contractLoaded,
  readContractLoaded,
  ethtodollar,
} from "../redux/actions";
import { connect, useSelector } from "react-redux";
import config from "./config";

function BlockchainProvider(props) {
  const { ethereum, account } = useWallet();
  useEffect(() => {}, []);

  useEffect(() => {
    init();
  }, [ethereum]);

  useEffect(() => {
    if (account) {
      props.dispatch(accountUpdate(account));
    }
  }, [account]);

  const init = () => {
    const web3 = new Web3(ethereum);

    const rpcs = [
      "https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79",
      "https://eth-rpc.gateway.pokt.network	",
      "https://rpc.ankr.com/eth	",
    ];
    var rpc = rpcs[Math.floor(Math.random() * rpcs.length)];

    const readWeb3 = new Web3(rpc);
    try {

      const SimpleContract = new web3.eth.Contract(
        abi,
        config.CONTRACT_ADDRESS
      );
      const ReadContract = new readWeb3.eth.Contract(
        abi,
        config.CONTRACT_ADDRESS
      );
      props.dispatch(contractLoaded(SimpleContract));
      props.dispatch(ethtodollar(props.eth));
      props.dispatch(readContractLoaded(ReadContract))
    } catch (err) {
      console.log(err);
    }
  };

  return <div></div>;
}
const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(BlockchainProvider);
